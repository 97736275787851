import Modal, { IModalProps, Size } from 'components/Modal'
import { Paragraph } from 'components/Typography'
import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { dataNoticeModalStyle } from './styles'

interface IFeatureConfirmModalProps {
  title: string
  description: string
  cancel_button: string
  confirm_button: string
}

export interface IFeatureFlagGroup {
  name: string
  description: string
  missing_message?: string
  confirmation: boolean
  confirmation_modal?: IFeatureConfirmModalProps
  features?: IFeatureFlag[]
  all_checked?: boolean
}

export interface IFeatureFlag {
  name: string
  default_value: boolean
  force_value?: boolean
  description: string
  group: string
  group_object?: IFeatureFlagGroup
  current_value?: boolean
}

export interface IFeatureFlagCurrent {
  enabled: boolean
}

export type TFeatureFlagsCurrentValues = Record<string, IFeatureFlagCurrent>

interface IFeatureFlagsContextProps {
  groups: IFeatureFlagGroup[]
  features: IFeatureFlag[]
  currentValues: TFeatureFlagsCurrentValues
  children: React.ReactNode
}

export interface IFeatureFlagsContext {
  groups: IFeatureFlagGroup[]
  groupIds: string[]
  featureIds: string[]
  getGroup: (group: string) => IFeatureFlagGroup
  getFeature: (feature: string) => IFeatureFlag
  featuresForGroupId: (group: string) => IFeatureFlag[]
  updateAllFeaturesInGroup: (group: string, value: boolean) => void
  updateFeature: (featureId: string, value: boolean) => void
  checkboxName: (featureId: string) => string
}

interface ITruncatedModalProps extends Omit<IModalProps, 'children'> {
  description: string
}

const FeatureFlagsContext = React.createContext({} as IFeatureFlagsContext)

const defaultDataNoticeProps: ITruncatedModalProps = {
  isOpen: false,
  size: Size.small,
  onRequestClose: () => {},
  onSave: () => {},
  title: 'Confirm?',
  description: '',
  largeTitle: true,
  cancelButton: 'Cancel',
  submitButton: 'Confirm',
}

export const FeatureFlagsContextProvider = ({
  groups,
  features,
  currentValues = {},
  children,
}: IFeatureFlagsContextProps): JSX.Element => {
  // Index the groups and features
  const [combinedGroups, setCombinedGroups] = useState<IFeatureFlagGroup[]>()
  const [allFeatures, setAllFeatures] = useState<IFeatureFlag[]>()
  const [groupIds, setGroupIds] = useState<string[]>()
  const [featureIds, setFeatureIds] = useState<string[]>()
  const [needsWarned, setNeedsWarned] = useState<Record<string, boolean>>({
    data_related: true,
  })
  const [dataNoticeProps, setDataNoticeProps] = useState<ITruncatedModalProps>(
    defaultDataNoticeProps,
  )

  // Check if ALL features in the group are checked
  const isAllCheckedInGroup = useCallback((group) => {
    let allChecked = !!group.features?.length
    for (const feature of group.features ?? []) {
      allChecked = allChecked && feature.current_value
    }
    return allChecked
  }, [])

  // Check if ANY features in the group are checked
  const isAnyCheckedInGroup = useCallback((group) => {
    let anyChecked = false
    for (const feature of group.features ?? []) {
      anyChecked = anyChecked || feature.current_value
    }
    return anyChecked
  }, [])

  useEffect(() => {
    const indexed: Record<string, IFeatureFlagGroup> = {
      // Scoops all the features with undefined groups.
      // Anything in this group's features list will have a hidden field created to retain value.
      _: {
        name: 'missing group',
        description: 'missing group',
        confirmation: false,
        features: [],
      },
    }
    const indexedFeatures: Record<string, IFeatureFlag> = {}

    // Index and stub groups
    groups.forEach((group) => {
      indexed[group.name] = group
      group.features = []
    })

    // Index features and assign features to groups
    features.forEach((feature) => {
      if (!feature.group) return
      const currentValue = currentValues
        ? currentValues[feature.name]
        : { enabled: false }
      feature.current_value =
        feature.force_value ?? currentValue?.enabled ?? false
      if (indexed[feature.group]) {
        feature.group_object = indexed[feature.group]
        indexed[feature.group].features.push(feature)
      } else {
        feature.group_object = indexed._
        indexed._.features.push(feature)
      }
      indexedFeatures[feature.name] = feature
    })

    // Update the all-checked marker for groups
    Object.values(indexed).forEach((group) => {
      group.all_checked = isAllCheckedInGroup(group)
    })

    setGroupIds(Object.keys(indexed))
    setCombinedGroups(Object.values(indexed))
    setAllFeatures(Object.values(indexedFeatures))
    setFeatureIds(Object.keys(indexedFeatures))
  }, [groups])

  // Grab one group by id
  const getGroup = useCallback(
    (group: string) => {
      const index = groupIds.indexOf(group)
      return combinedGroups[index] ?? null
    },
    [combinedGroups, groupIds],
  )

  // Get on feature by id
  const getFeature = useCallback(
    (feature: string) => {
      const index = featureIds.indexOf(feature)
      return allFeatures[index] ?? null
    },
    [allFeatures, featureIds],
  )

  // Indexed lookup of features, by group
  const featuresForGroupId = useCallback(
    (group: string): IFeatureFlag[] => {
      const fullGroup = getGroup(group)
      return fullGroup.features ?? []
    },
    [combinedGroups, groupIds],
  )

  // Group level checkmarks should toggle everything in the group on or off
  const updateAllFeaturesInGroup = useCallback(
    (group, value) => {
      const groupIndex = groupIds.indexOf(group)
      const fullGroup = combinedGroups[groupIndex]
      const features = fullGroup.features ?? []
      features.forEach((feature) => {
        feature.current_value = value
      })
      fullGroup.all_checked = value
      setCombinedGroups([...combinedGroups])
    },
    [combinedGroups, allFeatures],
  )

  // Enable/disable warnning for a given group
  const maybeEnableWarning = useCallback(
    (group: string, value: boolean) => {
      needsWarned[group] = value
      setNeedsWarned(_.cloneDeep(needsWarned))
    },
    [needsWarned],
  )

  // Internal function that actually makes the feature enablement change.
  // Put into it's own function because it may be called directly or via confirm submission.
  const actuallyUpdateFeature = useCallback(
    (index: number, value: boolean) => {
      const feature = allFeatures[index]
      feature.current_value = value
      if (feature.group_object) {
        feature.group_object.all_checked = isAllCheckedInGroup(
          feature.group_object,
        )
        if (
          needsWarned[feature.group] !== undefined &&
          !isAnyCheckedInGroup(feature.group_object)
        ) {
          maybeEnableWarning(feature.group, true)
        }
      }
      setAllFeatures([...allFeatures])
    },
    [allFeatures, featureIds],
  )

  // Enable/disable the confirm modal
  const maybeEnableConfirm = useCallback(
    (
      enable: boolean,
      onSave?: () => void,
      onClose?: () => void,
      groupObject?: IFeatureFlagGroup,
    ) => {
      if (enable) {
        const modalProps = _.cloneDeep(defaultDataNoticeProps)
        modalProps.isOpen = true
        modalProps.onSave = onSave
        modalProps.onRequestClose = onClose
        if (groupObject && groupObject.confirmation_modal) {
          modalProps.title =
            groupObject.confirmation_modal?.title ?? modalProps.title
          modalProps.description =
            groupObject.confirmation_modal?.description ??
            modalProps.description
          modalProps.cancelButton =
            groupObject.confirmation_modal?.cancel_button ??
            modalProps.cancelButton
          modalProps.submitButton =
            groupObject.confirmation_modal?.confirm_button ??
            modalProps.submitButton
        }
        setDataNoticeProps(modalProps)
      } else {
        setDataNoticeProps(defaultDataNoticeProps)
      }
    },
    [allFeatures, featureIds, dataNoticeProps],
  )

  // Update the value of a single feature
  const updateFeature = useCallback(
    (featureId: string, value: boolean) => {
      const index = featureIds.indexOf(featureId)
      if (index >= 0) {
        const feature = allFeatures[index]
        if (
          value &&
          (needsWarned[feature.group] || feature.group === 'data_related')
        ) {
          maybeEnableConfirm(
            true,
            () => {
              actuallyUpdateFeature(index, value)
              maybeEnableWarning(feature.group, false)
              maybeEnableConfirm(false)
            },
            () => {
              maybeEnableConfirm(false)
            },
            feature.group_object,
          )
        } else {
          actuallyUpdateFeature(index, value)
        }
      }
    },
    [allFeatures, featureIds],
  )

  const checkboxName = useCallback(
    (featureId: string) => `organization[features_${featureId}]`,
    [allFeatures, featureIds],
  )

  const missingGroupIndex = groupIds?.indexOf('_')
  const missingGroup =
    combinedGroups && missingGroupIndex > -1
      ? combinedGroups[missingGroupIndex]
      : undefined

  return (
    <FeatureFlagsContext.Provider
      value={{
        groups: combinedGroups,
        featureIds,
        groupIds,
        getGroup,
        getFeature,
        featuresForGroupId,
        updateAllFeaturesInGroup,
        updateFeature,
        checkboxName,
      }}>
      {children}
      <Modal {...dataNoticeProps} css={dataNoticeModalStyle}>
        <Paragraph>{dataNoticeProps.description}</Paragraph>
      </Modal>
      {missingGroup?.features?.map((feature) => (
        <input
          type="hidden"
          key={feature.name}
          name={checkboxName(feature.name)}
          defaultValue={feature?.current_value ?? false ? '1' : '0'}
        />
      ))}
    </FeatureFlagsContext.Provider>
  )
}

export const useFeatureFlagsContext = (): IFeatureFlagsContext =>
  useContext(FeatureFlagsContext)
