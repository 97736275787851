import { Paragraph } from 'components/Typography'
import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'
import { useContext } from 'react'
import Container from 'components/Container'
import { CustomRulesContext } from './CustomRulesContext'
import { RulePreferenceType } from './types'
import RulesDndList from './RulesDndList'

const customRulesPageContainerStyle = css({
  marginLeft: 40, // amount of px of the first column for drag and drop
})

const descriptionStyle = css({
  marginBottom: spacings.grid_gap_basis_num * 3,
})

const dividerStyle = css({
  borderTop: `1px solid ${colors.borders.gray}`,
  height: 1,
  width: '100%',
})

export default function CustomRulesComponent(): JSX.Element {
  const {
    rules,
    setRules,
    preferences,
    setPreferences,
    labelOptions,
    criteriaOptions,
    defaultEmptyInterGroupRule,
    defaultEmptyIntraGroupRule,
    addCustomRule,
    removeCustomRule,
    updateRule,
    errors,
  } = useContext(CustomRulesContext)
  return (
    <div css={customRulesPageContainerStyle}>
      <div
        css={{
          width: 'min-content',
        }}>
        <Paragraph css={descriptionStyle}>
          <b>Guidance for program administrator:</b> Remember to create and
          assign labels before proceeding to custom matching. Then, once you
          have created the rules, <b>drag and drop</b> them to rank them in the
          order you’d like them to apply, from the <b>most important rule</b> at
          the top to the <b>least important rule</b> at the bottom.
        </Paragraph>
        <Container
          direction="column"
          alignment="start"
          css={{
            gap: spacings.grid_gap_basis_num * 2,
          }}>
          <RulesDndList
            errors={errors}
            rules={rules}
            setRules={setRules}
            defaultItems={
              preferences.length > 0 || rules.length > 0
                ? []
                : [
                    defaultEmptyInterGroupRule(RulePreferenceType.rule),
                    defaultEmptyIntraGroupRule(RulePreferenceType.rule),
                  ]
            }
            labelOptions={labelOptions}
            criteriaOptions={criteriaOptions}
            rulePreferenceType={RulePreferenceType.rule}
            onAddRule={(ruleType) =>
              addCustomRule(RulePreferenceType.rule, ruleType)
            }
            onRemoveRule={(index) =>
              removeCustomRule(index, RulePreferenceType.rule)
            }
            name={`mentorship_program[custom_rules_attributes][${RulePreferenceType.rule}]`}
            updateRule={(index, preference, ruleData) =>
              updateRule(RulePreferenceType.rule, index, preference, ruleData)
            }
          />
          <hr css={dividerStyle} />
          <RulesDndList
            errors={errors}
            rules={preferences}
            setRules={setPreferences}
            defaultItems={
              preferences.length > 0 || rules.length > 0
                ? []
                : [
                    defaultEmptyInterGroupRule(RulePreferenceType.preference),
                    defaultEmptyIntraGroupRule(RulePreferenceType.preference),
                  ]
            }
            labelOptions={labelOptions}
            criteriaOptions={criteriaOptions}
            rulePreferenceType={RulePreferenceType.preference}
            onAddRule={(ruleType) =>
              addCustomRule(RulePreferenceType.preference, ruleType)
            }
            onRemoveRule={(index) =>
              removeCustomRule(index, RulePreferenceType.preference)
            }
            name={`mentorship_program[custom_rules_attributes][${RulePreferenceType.preference}]`}
            updateRule={(index, preference, ruleData) =>
              updateRule(
                RulePreferenceType.preference,
                index,
                preference,
                ruleData,
              )
            }
          />
        </Container>
      </div>
    </div>
  )
}
