import Container from 'components/Container/Container'
import {
  IIntergroupRuleObj,
  ILabel,
  RulePreference,
  rulePreferenceOptions,
  preferencePreferenceOptions,
  RuleType,
  TLabelOption,
  RulePreferenceType,
} from './types'
import Select from 'components/Inputs/Select'
import { css } from '@emotion/react'
import TrashIcon from 'images/icons/trash.svg'
import { colors } from 'stylesheets/theme'

interface ICustomRuleItemProps {
  rule?: Omit<IIntergroupRuleObj, 'priority_order' | 'group_type'>
  index: number
  labelOptions: TLabelOption[]
  onRemoveRule?: () => void
  updateInterGroupRule: (
    preference: RulePreference,
    ruleData: IIntergroupRuleObj['rule_data'],
  ) => void
  errors: Record<string, string[]>
}

const inputSelectStyle = css({
  textTransform: 'capitalize',
  marginBottom: 0,
})

const preferenceInputStyle = css({
  minWidth: 200,
})

const targetInputStyle = css({
  minWidth: 300,
})

const trashIconStyle = css({
  cursor: 'pointer',
  marginLeft: 10,
  minWidth: 20,
  minHeight: 20,
  '&:hover': {
    path: {
      stroke: colors.text.text_9,
    },
  },
})

const inputsContainerStyle = css({
  alignItems: 'baseline',
  '.validation-error-message': {
    color: colors.red,
    fontSize: '11px',
    lineHeight: '22px',
    display: 'block',

    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
})

export default function IntergroupCustomRuleItem({
  rule: defaultRule,
  index,
  labelOptions = [],
  onRemoveRule = () => {},
  updateInterGroupRule,
  errors,
}: ICustomRuleItemProps): JSX.Element {
  const {
    preference,
    rule_data: ruleData,
    rule_preference_type,
    _destroy,
    id,
  } = defaultRule

  const name = `mentorship_program[custom_rules_attributes][${rule_preference_type}][${index}]`

  const handleUpdatePreference = (value: RulePreference) => {
    updateInterGroupRule(value, ruleData)
  }

  const handleUpdateTarget = (targetData: string[]) => {
    return targetData.map((labelValue) => {
      const labelData = labelOptions.find(
        (option) => option.value == labelValue,
      )
      return {
        name: labelData.label,
        object_id: labelData.labelId,
        object_type: labelData.labelType,
      } as ILabel
    })
  }

  const handleUpdateTarget1 = (targetData: string[]) => {
    const updatedTarget = handleUpdateTarget(targetData)
    updateInterGroupRule(preference, { ...ruleData, target_1: updatedTarget })
  }

  const handleUpdateTarget2 = (targetData: string[]) => {
    const updatedTarget = handleUpdateTarget(targetData)
    updateInterGroupRule(preference, { ...ruleData, target_2: updatedTarget })
  }

  return (
    <Container
      alignment="center"
      justify="space-between"
      css={{
        width: '100%',
      }}>
      <Container css={inputsContainerStyle}>
        <Select
          name={`${name}[preference]`}
          css={[inputSelectStyle, preferenceInputStyle]}
          options={
            rule_preference_type === RulePreferenceType.rule
              ? rulePreferenceOptions
              : preferencePreferenceOptions
          }
          value={preference?.toString()}
          onChange={(inputData: Record<string, string>) =>
            handleUpdatePreference(
              parseInt(inputData[`${name}[preference]`], 10),
            )
          }
          placeholder="Select option"
          errors={errors[`${name}[preference]`] || undefined}></Select>
        match
        <Select
          name={`${name}[target_1]`}
          css={[inputSelectStyle, targetInputStyle]}
          options={labelOptions}
          isMulti
          value={ruleData?.target_1?.map(
            (label) => `${label.object_type}.${label.object_id.toString()}`,
          )}
          onChange={(inputData: Record<string, string[]>) =>
            handleUpdateTarget1(inputData[`${name}[target_1]`])
          }
          placeholder="Select labels"
          errors={errors[`${name}[target_1]`] || undefined}></Select>
        with
        <Select
          name={`${name}[target_2]`}
          css={[inputSelectStyle, targetInputStyle]}
          options={labelOptions}
          isMulti
          value={ruleData?.target_2?.map(
            (label) => `${label.object_type}.${label.object_id.toString()}`,
          )}
          onChange={(inputData: Record<string, string[]>) =>
            handleUpdateTarget2(inputData[`${name}[target_2]`])
          }
          placeholder="Select labels"
          errors={errors[`${name}[target_2]`] || undefined}></Select>
      </Container>
      <TrashIcon css={trashIconStyle} onClick={onRemoveRule} />
      {_destroy && (
        <input type="hidden" name={`${name}[_destroy]`} value="true" />
      )}
      <input
        name={`${name}[id]`}
        type="hidden"
        defaultValue={id?.toString() || 0}
        id={`${name}_id`}
      />
      <input
        type="hidden"
        name={`${name}[group]`}
        value={RuleType.intergroup}
      />
      <input
        type="hidden"
        name={`${name}[rule]`}
        value={JSON.stringify(ruleData)}
      />
    </Container>
  )
}
