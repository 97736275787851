import { useState, useMemo, useEffect, useCallback } from 'react'
import Modal, { IModalProps, Size } from 'components/Modal'
import Input from 'components/Input'
import { modalStyle } from '../modal-style'
import { IApplicant, INotYetMatchedApplicant } from 'types'
import { FormFieldContainer, FormFieldsRow } from 'components/Forms'
import Select, { IOption } from 'components/Inputs/Select'
import { css } from '@emotion/react'
import axios from 'axios'
import Button, { ButtonComponentType, Variant } from 'components/Button'

const textareaCss = css({
  '.input-wrapper textarea': {
    minHeight: 250,
  },
})

interface IEditMatchReasoningModalProps
  extends Omit<
    IModalProps,
    'size' | 'children' | 'submitButton' | 'cancelButton' | 'title' | 'onSave'
  > {
  participants: IApplicant[]
  participant: INotYetMatchedApplicant
  onSave?: (selectedParticipant: IApplicant, matchReasoning: string) => void
}

type TApplicantOption = IOption & {
  participant: IApplicant
}

interface IRegenerateResponse {
  data: {
    reason: string
  }
}

function onSelectPartner(
  url,
  app1Id,
  app2Id,
  withReason: (reason: string) => void,
  onError: () => void,
) {
  axios
    .post(url, {
      app1_id: app1Id,
      app2_id: app2Id,
      authenticity_token: window.authenticity_token,
    })
    .then(({ data: { reason } }: IRegenerateResponse) => {
      withReason && withReason(reason)
    })
    .catch(() => {
      window.flash('Could not regenerate that match reason.', 'error')
      onError && onError()
    })
}

export default function MatchManuallyModal({
  participant,
  participants,
  onSave,
  onRequestClose,
  ...props
}: IEditMatchReasoningModalProps): JSX.Element {
  const [loadingReason, setLoadingReason] = useState(false)
  const [matchParticipant, setMatchParticipant] = useState(null)
  const [matchReasoning, setMatchReasoning] = useState(null)
  const matchParticipantFieldName = 'select-match-participant'
  const matchReasoningFieldName = 'match-reasoning'

  const regenReason = useCallback(() => {
    setLoadingReason(true)
    onSelectPartner(
      participant?.regenerate_reason_from_ids_url,
      participant?.id,
      matchParticipant?.id,
      (reason: string) => {
        setMatchReasoning(reason)
        setLoadingReason(false)
      },
      () => {
        setLoadingReason(false)
      },
    )
  }, [participant, matchParticipant])

  useEffect(() => {
    if (!matchParticipant) return
    regenReason()
  }, [matchParticipant, participant])

  const validMatch = useMemo(() => !!matchParticipant, [matchParticipant])

  return (
    <Modal
      {...props}
      isSubmitDisabled={
        loadingReason || participants.length === 0 || !validMatch
      }
      css={[modalStyle]}
      size={Size.large}
      submitButton="confirm"
      cancelButton="Cancel"
      title={`Find a suitable match for ${participant.full_name}`}
      onRequestClose={() => {
        setMatchParticipant(null)
        setLoadingReason(false)
        setMatchReasoning(null)
        onRequestClose && onRequestClose()
      }}
      onSave={() => onSave(matchParticipant, matchReasoning)}>
      <p>
        Please select the most ideal partner for {participant.first_name}. You
        can search the dropdown and select a participant. This pair will be
        moved to the “Matches for review” table once confirmed.
      </p>
      <FormFieldContainer
        fieldId={matchParticipantFieldName}
        label="Participant name*"
        css={{
          width: 365,
          '&.input-component-wrapper label.label': {
            fontWeight: 'normal',
          },
        }}>
        <Select<TApplicantOption>
          value={matchParticipant ? matchParticipant.id.toString() : ''}
          onChange={(value) => {
            const selectedParticipant = participants.find(
              (p) => p.id.toString() === value[matchParticipantFieldName],
            )

            if (selectedParticipant) {
              setMatchParticipant(selectedParticipant)
            }
          }}
          name={matchParticipantFieldName}
          placeholder="Search and select"
          options={participants.map((p) => ({
            label: p.first_name + ' ' + p.last_name,
            value: p.id.toString(),
            participant: p,
          }))}
        />
      </FormFieldContainer>
      <FormFieldContainer
        fieldId={matchReasoningFieldName}
        label="Match reasoning"
        css={{
          '&.input-component-wrapper label.label': {
            fontWeight: 'normal',
          },
        }}>
        <Input
          css={textareaCss}
          type="textarea"
          name={matchReasoningFieldName}
          value={matchReasoning}
          onChange={(value) => {
            setMatchReasoning(value[matchReasoningFieldName])
          }}
        />
      </FormFieldContainer>
      <FormFieldsRow>
        <Button
          disabled={loadingReason || !matchParticipant}
          onClick={regenReason}
          as={ButtonComponentType.LINK}
          variant={Variant.LINK}
          title="Regenerates the match reason based on the raw matching data. This overwrites any previously entered match reason.">
          regenerate
        </Button>
      </FormFieldsRow>
    </Modal>
  )
}
