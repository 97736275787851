import Container from 'components/Container/Container'
import {
  IIntragroupRule,
  IIntragroupRuleObj,
  ILabel,
  RulePreference,
  RulePreferenceType,
  RuleType,
  TLabelOption,
  preferencePreferenceOptions,
  rulePreferenceOptions,
} from './types'
import Select, { IOption } from 'components/Inputs/Select'
import { css } from '@emotion/react'
import TrashIcon from 'images/icons/trash.svg'
import { colors } from 'stylesheets/theme'
import { useCallback, useMemo } from 'react'

interface ICustomRuleItemProps {
  rule?: Omit<IIntragroupRuleObj, 'priority_order' | 'group_type'>
  index: number
  labelOptions: TLabelOption[]
  criteriaOptions: IOption[]
  onRemoveRule?: () => void
  updateIntraGroupRule: (
    preference: RulePreference,
    ruleData: IIntragroupRule,
  ) => void
  errors: Record<string, string[]>
}

const inputSelectStyle = css({
  textTransform: 'capitalize',
  marginBottom: 0,
})

const preferenceInputStyle = css({
  minWidth: 200,
})

const targetInputStyle = css({
  minWidth: 300,
})

const criteriaInputStyle = css({
  minWidth: 350,
})

const trashIconStyle = css({
  cursor: 'pointer',
  marginLeft: 10,
  minWidth: 20,
  minHeight: 20,
  '&:hover': {
    path: {
      stroke: colors.text.text_9,
    },
  },
})

const everyoneLabel = { value: '', label: 'Everyone' }

const inputsContainerStyle = css({
  alignItems: 'baseline',
  '.validation-error-message': {
    color: colors.red,
    fontSize: '11px',
    lineHeight: '22px',
    display: 'block',

    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
})

export default function IntragroupCustomRuleItem({
  rule,
  index,
  labelOptions = [],
  criteriaOptions = [],
  onRemoveRule = () => {},
  updateIntraGroupRule,
  errors,
}: ICustomRuleItemProps): JSX.Element {
  const {
    preference,
    rule_data: ruleData,
    rule_preference_type,
    _destroy,
    id,
  } = useMemo(() => rule, [rule])
  const name = `mentorship_program[custom_rules_attributes][${rule_preference_type}][${index}]`

  const targetOptions = useMemo(
    () => [everyoneLabel, ...labelOptions],
    [labelOptions],
  )

  const handleUpdateTarget = useCallback(
    (targetData: string[] | string) => {
      if (Array.isArray(targetData)) {
        const isEveryoneSelected = targetData.includes(everyoneLabel.value)

        // If 'Everyone' is selected, then the value should be the label for 'Everyone', changing from multi-select to single-select
        updateIntraGroupRule(preference, {
          ...ruleData,
          target_1: isEveryoneSelected
            ? []
            : (targetData as string[])
                .filter((label) => label !== everyoneLabel.value)
                .map((labelValue) => {
                  const labelData = labelOptions.find(
                    (option) => option.value == labelValue,
                  )
                  return {
                    name: labelData.label,
                    object_id: labelData.labelId,
                    object_type: labelData.labelType,
                  } as ILabel
                }),
        })
      } else {
        // If targetData is a string, then it means it's a single select, and the value should be the label for 'Everyone'
        const isEveryoneSelected = targetData == everyoneLabel.value
        updateIntraGroupRule(preference, {
          ...ruleData,
          target_1: isEveryoneSelected
            ? []
            : [...ruleData.target_1, targetData].map((labelValue) => {
                const labelData = labelOptions.find(
                  (option) => option.value == labelValue,
                )
                return {
                  name: labelData.label,
                  object_id: labelData.labelId,
                  object_type: labelData.labelType,
                } as ILabel
              }),
        }) //Changeing from single-select to multi-select)
      }
    },
    [preference, ruleData, labelOptions, updateIntraGroupRule],
  )

  const updateCriteria = useCallback(
    (newCriteria: number) => {
      updateIntraGroupRule(preference, {
        ...ruleData,
        criteria: newCriteria,
      })
    },
    [preference, ruleData, updateIntraGroupRule],
  )

  const targetValue = useMemo(() => {
    if (ruleData?.target_1?.length === 0) {
      return everyoneLabel.value
    } else {
      return ruleData?.target_1?.map(
        (label) => `${label.object_type}.${label.object_id.toString()}`,
      )
    }
  }, [ruleData])

  return (
    <Container
      alignment="center"
      justify="space-between"
      css={{
        width: '100%',
      }}>
      <Container css={inputsContainerStyle}>
        <Select
          name={`${name}[preference]`}
          css={[inputSelectStyle, preferenceInputStyle]}
          options={
            rule_preference_type === RulePreferenceType.rule
              ? rulePreferenceOptions
              : preferencePreferenceOptions
          }
          value={preference?.toString()}
          onChange={(inputData: Record<string, string>) => {
            updateIntraGroupRule(
              parseInt(inputData[`${name}[preference]`], 10),
              ruleData,
            )
          }}
          placeholder="Select option"
          errors={errors[`${name}[preference]`] || undefined}></Select>
        <Select
          name={`${name}[criteria]`}
          css={[inputSelectStyle, criteriaInputStyle]}
          options={criteriaOptions}
          value={ruleData?.criteria ? ruleData.criteria.toString() : null}
          onChange={(inputData: Record<string, string>) => {
            updateCriteria(parseInt(inputData[`${name}[criteria]`], 10))
          }}
          placeholder="Select criteria"
          errors={errors[`${name}[criteria]`] || undefined}></Select>
        for
        <Select
          name={`${name}[target_1]`}
          css={[inputSelectStyle, targetInputStyle]}
          options={targetOptions}
          isMulti={targetValue?.length > 0}
          getOptionLabel={(option) => option.label}
          value={targetValue}
          onChange={(inputData: Record<string, string[] | string>) => {
            handleUpdateTarget(inputData[`${name}[target_1]`])
          }}
          placeholder="Select labels"
          errors={errors[`${name}[target_1]`] || undefined}></Select>
      </Container>
      <TrashIcon css={trashIconStyle} onClick={onRemoveRule} />
      {_destroy && (
        <input type="hidden" name={`${name}[_destroy]`} value="true" />
      )}
      <input
        name={`${name}[id]`}
        type="hidden"
        defaultValue={id?.toString() || 0}
        id={`${name}_id`}
      />
      <input
        type="hidden"
        name={`${name}[group]`}
        value={RuleType.intragroup}
      />
      <input
        type="hidden"
        name={`${name}[rule]`}
        value={JSON.stringify(ruleData)}
      />
    </Container>
  )
}
