import { IOption } from 'components/Inputs/Select'

export const ruleTypes = ['intergroup', 'intragroup'] as const
export type TRuleType = (typeof ruleTypes)[number]

export enum RuleType {
  intergroup = 0,
  intragroup = 1,
}

export enum RulePreferenceType {
  rule = 0,
  preference = 1,
}

export enum RulePreference {
  never = 0,
  always = 1,
}

// What FE is expecting
export interface IRuleBase {
  id: number
  priority_order: number
  rule_preference_type: RulePreferenceType
  preference: RulePreference
  _destroy?: boolean
  hidden?: boolean
}

export interface IIntergroupRuleObj extends IRuleBase {
  group_type: RuleType.intergroup
  rule_data: IIntergroupRule
}

export interface IIntragroupRuleObj extends IRuleBase {
  group_type: RuleType.intragroup
  rule_data: IIntragroupRule
}

export type TMentorshipLabelType =
  | 'SpecialMentorshipLabel'
  | 'MentorshipApplicationLabel'

export interface ILabel {
  name: string
  object_id: number
  object_type: TMentorshipLabelType
}

export interface IIntergroupRule {
  target_1: ILabel[]
  target_2: ILabel[]
}

export type RuleArray = Array<IIntergroupRuleObj | IIntragroupRuleObj>

export enum IntragroupCriteria {
  'same location' = 1,
  'same business unit' = 2,
  'same title' = 3,
  'same time zone' = 4,
}

export interface IIntragroupRule {
  criteria: IntragroupCriteria
  target_1: ILabel[]
}

export const rulePreferenceOptions = [
  { value: '0', label: 'Never' },
  { value: '1', label: 'Always' },
]

export const preferencePreferenceOptions = [
  { value: '0', label: 'Preferably do not' },
  { value: '1', label: 'Preferably' },
]

export type TLabelOption = IOption & {
  labelType: TMentorshipLabelType
  labelId: number
}
