import { useState } from 'react'
import Button, { Variant } from 'components/Button'
import ConfirmationModal from 'components/ConfirmationModal'

const spinnerImage = require('images/spinner-modal.gif')

interface PublishNowButtonProps {
  isCohort?: boolean
}

export default function PublishNowButton({
  isCohort = false,
}: PublishNowButtonProps): JSX.Element {
  const [openModal, setOpenModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPublished, setIsPublished] = useState(false)

  const handleConfirmPublish = () => {
    if (isSubmitting) return
    setIsSubmitting(true)

    const forms = window.document.getElementsByClassName('mindr-form')
    const form = !!forms.length ? (forms[0] as HTMLFormElement) : undefined

    if (!form) {
      setIsSubmitting(false)
      return
    }

    const publishedEl = document.createElement('input')
    publishedEl.setAttribute('type', 'hidden')
    publishedEl.setAttribute('name', 'event[published]')
    publishedEl.setAttribute('value', 'true')
    form.appendChild(publishedEl)

    const schedulePublishEls = document.getElementsByName(
      'event[schedule_publish]',
    )

    if (schedulePublishEls && !!schedulePublishEls.length) {
      schedulePublishEls.forEach((el) => el.remove())
    }

    const publishingDateEls = document.querySelectorAll(
      'form.mindr-form [name="event[formatted_publishing_date]"]',
    )

    if (publishingDateEls && !!publishingDateEls.length) {
      publishingDateEls.forEach((el) => el.removeAttribute('value'))
    }

    const publishingTimeEls = document.querySelectorAll(
      'form.mindr-form [name="event[formatted_publishing_time]"]',
    )

    if (publishingTimeEls && !!publishingTimeEls.length) {
      publishingTimeEls.forEach((el) => el.removeAttribute('value'))
    }

    form.requestSubmit()
    setOpenModal(false)
    setIsPublished(true)
  }

  return (
    <>
      <Button
        variant={Variant.PRIMARY}
        type="button"
        disabled={isSubmitting || isPublished}
        onClick={() => setOpenModal(true)}>
        {isPublished ? 'Published' : 'Publish Now'}
      </Button>
      {!isPublished && (
        <ConfirmationModal
          isOpen={openModal && !isSubmitting}
          title="Publish Event"
          onConfirm={handleConfirmPublish}
          onCancel={() => setOpenModal(false)}
          submitButton="Publish Now">
          {isCohort
            ? "Are you sure you want to publish this event? Publishing the event means it will display in the event directory, be sent to your cohort's participants as an update. You will be able to edit the event once it is published."
            : "Are you sure you want to publish this event? Publishing the event means it will display in the event directory, be sent to your community's subscribers as an update and appear in the organization's community digest. You will be able to edit the event once it is published."}
        </ConfirmationModal>
      )}
      <ConfirmationModal
        isOpen={isSubmitting}
        title="Publishing..."
        onConfirm={() => {}}
        onCancel={() => {}}
        submitButton=""
        cancelButton="">
        <div className="text-center publishing-modal-content">
          <img src={spinnerImage} alt="Publishing..." />
        </div>
      </ConfirmationModal>
    </>
  )
}
