import LabelIcon from 'images/icons/label.svg'
import { colors, spacings, zIndex } from 'stylesheets/theme'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import Container from 'components/Container/Container'
import Input from 'components/Input'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import { css } from '@emotion/react'
import PlusIcon from 'images/icons/plus.svg'
import { Paragraph } from 'components/Typography'
import axios from 'axios'
import { IMentorshipApplicationLabel } from 'types'
import { ITableData } from 'components/Table'
import _ from 'lodash'
import EditLabelsModalComponent from './EditLabelsModal/EditLabelsModalComponent'

const addLabelButtonDropdownContainerStyle = css({
  width: 383,
  display: 'flex',
  justifyContent: 'flex-end',
})

const addLabelsDropdownContainerStyle = css({
  position: 'absolute',
  zIndex: zIndex.menu,
  gap: 0,
  marginTop: spacings.grid_gap_basis_num * 3,
  width: 383,
  borderRadius: spacings.radius,
  padding: spacings.grid_gap_basis_num * 2,
  backgroundColor: colors.backgrounds.white,
  border: `1px solid ${colors.borders.gray}`,
  boxShadow: '0px 2px 6px 0px #0000001A',
})

const labelInputStyle = css({
  width: '100%',
  marginBottom: spacings.grid_gap_basis_num / 2,
})

const dropdownBoxStyle = css({
  width: '100%',
  gap: 0,
  borderRadius: spacings.radius,
  border: `0.5px solid ${colors.borders.gray}`,
  boxShadow: '0px 2px 6px 0px #0000001A',
  '.list-item:not(:first-child)': {
    borderTop: `0.5px solid ${colors.borders.gray}`,
  },
  maxHeight: spacings.grid_gap_basis_num * 4 * 5.5, // We only want to show at a max 5.5 items
  overflowY: 'auto',
})

const listItemStyle = css({
  textTransform: 'capitalize',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: spacings.grid_gap_basis,
  minHeight: spacings.grid_gap_basis_num * 4,
  ':hover': {
    backgroundColor: colors.backgrounds.teal,
  },
})

const createNewLabelLinkStyle = css({
  svg: {
    path: { stroke: colors.links.blue },
    marginRight: spacings.grid_gap_basis_num / 2,
  },
})

const editLabelsButtonStyle = css({
  marginTop: 24,
  alignSelf: 'flex-end',
})

interface IApplicationLabelsDropdownProps {
  defaultLabels: IMentorshipApplicationLabel[]
  createLabelsLink?: string
  editLabelsLink?: string
  assignApplicationLabelLink?: string
  searchLabelsLink?: string
  updateTableData?: (data: ITableData) => void
  selectedRows?: any[]
  table: string
}

export default function ApplicationLabelsDropdown({
  defaultLabels = [],
  createLabelsLink,
  editLabelsLink,
  assignApplicationLabelLink,
  searchLabelsLink,
  updateTableData,
  selectedRows,
  table,
}: IApplicationLabelsDropdownProps): JSX.Element {
  const [openDropdown, setOpenDropdown] = useState(false)
  const [labelInputErrors, setLabelInputErrors] = useState<string[]>([])
  const [labels, setLabels] =
    useState<IMentorshipApplicationLabel[]>(defaultLabels)
  const [labelInput, setLabelInput] = useState<string>('')
  const [isEditLabelsModalOpen, setIsEditLabelsModalOpen] = useState(false)
  const { tables } = useMentorshipExchangeDetailsContext()
  const currentPage = tables.not_yet_matched.tableData.paginator.current_page
  const selectedFilters = tables.not_yet_matched.selectedFilters
  // Handle closing dropdown when user clicks outside of the box
  const dropdownRef = useRef(null)

  const clearLabelInput = useCallback(() => {
    setLabelInput('')
  }, [setLabelInput])

  const clearErrors = useCallback(() => {
    setLabelInputErrors([])
  }, [labelInputErrors])

  /**
   * Create a new label and add it to top of the list of labels
   */
  const handleAddLabel = useCallback(() => {
    clearErrors()
    axios
      .post(createLabelsLink, {
        label: labelInput,
        authenticity_token: window.authenticity_token,
      })
      .then((response) => {
        setLabels(response.data.labels)
        clearLabelInput()
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          setLabelInputErrors(error.response.data.errors)
        }
      })
  }, [setLabels, labelInput, clearLabelInput])

  /**
   * Assign selected label to selected applications
   */
  const handleAssignLabelToApplications = useCallback(
    (labelId) => {
      if (selectedRows.length == 0) {
        window.flash(
          'Please select applicants before assigning labels.',
          'alert',
        )
        return
      }
      axios
        .post(assignApplicationLabelLink, {
          page: currentPage,
          ...selectedFilters,
          label_id: labelId,
          application_ids: selectedRows.map((row) => row.id),
          table,
          authenticity_token: window.authenticity_token,
        })
        .then((response) => {
          updateTableData(response.data.table.data)
        })
    },
    [selectedRows, table],
  )

  /**
   * Closes dropdown when users clicks outside of the dropdown
   */
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(false)
    }
  }

  const debouncedSearch = useCallback(
    _.debounce((query) => {
      axios
        .post(searchLabelsLink, {
          query,
          authenticity_token: window.authenticity_token,
        })
        .then((response) => {
          setLabels(response.data.labels)
        })
        .catch((error) => {
          console.error('Error searching labels:', error)
        })
    }, 300),
    [searchLabelsLink],
  )

  useEffect(() => {
    debouncedSearch(labelInput)
  }, [labelInput, debouncedSearch])

  /**
   * Add event listener to close dropdown when user clicks outside of the dropdown
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const labelInputName = 'application-label-input'

  // Translate a list of MentorshipApplicationLabels into a list of IOptions
  const labelOptions = useMemo(() => {
    return labels
      .map((label) => ({
        label: label.name,
        value: label.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [labels])

  /**
   * Called when dropdown is opened
   * Reset states
   */
  const onOpenDropdown = useCallback(() => {
    clearLabelInput()
    clearErrors()
    setOpenDropdown(true)
  }, [clearLabelInput, clearErrors, setOpenDropdown])

  return (
    <div css={addLabelButtonDropdownContainerStyle}>
      <Button
        startIcon={
          <LabelIcon
            css={{
              path: {
                fill: colors.links.blue,
              },
              marginRight: spacings.grid_gap_basis_num / 2,
            }}
          />
        }
        variant={Variant.LINK}
        css={{
          color: colors.links.blue,
        }}
        onClick={onOpenDropdown}>
        Add Labels
      </Button>
      {openDropdown && (
        <Container
          ref={dropdownRef}
          css={addLabelsDropdownContainerStyle}
          direction="column">
          <Input
            type="text"
            name={labelInputName}
            placeholder="Start typing a label..."
            value={labelInput}
            onChange={(e: Record<string, string>) => {
              setLabelInput(e[labelInputName])
            }}
            error={labelInputErrors.length > 0 ? labelInputErrors[0] : ''}
            css={labelInputStyle}></Input>
          <Container css={dropdownBoxStyle} direction="column">
            <div
              css={listItemStyle}
              className="list-item"
              onClick={handleAddLabel}>
              <Button
                as={ButtonComponentType.LINK}
                variant={Variant.LINK}
                startIcon={<PlusIcon />}
                css={createNewLabelLinkStyle}>
                Create new label
              </Button>
            </div>
            {labelOptions.map((option) => (
              <div
                key={option.value}
                css={listItemStyle}
                className="list-item"
                onClick={() => handleAssignLabelToApplications(option.value)}>
                <Paragraph>{option.label}</Paragraph>
              </div>
            ))}
          </Container>
          <Button
            as={ButtonComponentType.BUTTON}
            variant={Variant.LINK}
            css={editLabelsButtonStyle}
            disabled={labels.length == 0}
            onClick={() => setIsEditLabelsModalOpen(true)}>
            Edit labels
          </Button>
        </Container>
      )}
      <EditLabelsModalComponent
        labels={labels}
        updateLabelsLink={editLabelsLink}
        isModalOpen={isEditLabelsModalOpen}
        setModalOpen={setIsEditLabelsModalOpen}
      />
    </div>
  )
}
