import Container from 'components/Container'
import Input from 'components/Input'
import { useContext } from 'react'
import { IMentorshipApplicationLabel } from 'types'
import TrashIcon from 'images/icons/trash.svg'
import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'
import { EditLabelsModalContext } from './EditLabelsModalContext'

const trashCanIconStyle = css({
  minWidth: 20,
  minHeight: 20,
  cursor: 'pointer',
  marginBottom: spacings.grid_gap_basis_num / 2,
  path: {
    stroke: colors.text.text_7c,
  },
  ':hover': {
    path: {
      stroke: colors.text.text_9,
    },
  },
})

const lockedTrashCanIconStyle = css({
  opacity: 0.5,
  cursor: 'not-allowed',
})

const labelInputStyle = css({
  width: '100%',
  marginBottom: spacings.grid_gap_basis_num / 2,
  textTransform: 'capitalize',
})

const editLabelItemStyle = css({
  width: '100%',
  gap: spacings.grid_gap_basis_num * 2,
})

interface IEditLabelItemProps {
  label: IMentorshipApplicationLabel
}

export default function EditLabelItem({
  label,
}: IEditLabelItemProps): JSX.Element {
  const { renameLabel, removeLabel } = useContext(EditLabelsModalContext)
  const editLabelInputName = 'edit-label-input'
  const locked = label.locked
  return (
    <Container css={editLabelItemStyle}>
      <Input
        type="text"
        value={label.name}
        name={editLabelInputName}
        onChange={(inputData: Record<string, string>) =>
          renameLabel(label, inputData[editLabelInputName])
        }
        css={labelInputStyle}
        readOnly={locked}></Input>
      <TrashIcon
        css={[trashCanIconStyle, locked && lockedTrashCanIconStyle]}
        onClick={() => {
          if (!locked) {
            removeLabel(label)
          }
        }}
      />
    </Container>
  )
}
